import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const CustomCard = ({ image, header, description }) => (
  <Card className="custom-card">
    <Row className="h-100 no-gutters">
      <Col xs={12} md={5} className="image-col">
        <div className="image-container">
          <Card.Img src={image} className="custom-card-image" />
        </div>
      </Col>
      <Col xs={12} md={7} className="text-col">
        <Card.Body>
          <Card.Title className="font-weight-bold">{header}</Card.Title>
          <Card.Text className="text-sm">{description}</Card.Text>
        </Card.Body>
      </Col>
    </Row>
  </Card>
);

export default CustomCard;

