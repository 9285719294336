import React from 'react';
import '../assets/css/Work.css'; // Import the CSS file for custom styling
import image17 from '../assets/images/image-17.jpg';
import image18 from '../assets/images/image-18.jpg';
import image19 from '../assets/images/image-19.jpg';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Works = () => {
  const projects = [
    { id: 1, image: image17, category: 'design' },
    { id: 2, image: image18, category: 'construction' },
    { id: 3, image: image19, category: 'design' },
    { id: 4, image: image17, category: 'design' },
    { id: 5, image: image18, category: 'construction' },
    { id: 6, image: image19, category: 'design' },
    { id: 7, image: image17, category: 'design' },
    { id: 8, image: image18, category: 'construction' },
    /*{ id: 9, image: image19, category: 'design' },
    { id: 10, image: image17, category: 'design' },
    { id: 11, image: image18, category: 'construction' },
    { id: 12, image: image19, category: 'design' },*/
    // Add more projects as needed
  ];

  return (
    <section className="section" id="our-work">
      <div className="section-content text-center container">
        <Container className="text-center about-font">
          <div className="d-flex justify-content-center justify-content-md-between align-items-end">
            <div className="text-center text-md-left">
              <small className="fs-1-ask font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">
                Latest projects gallery
              </small>
              <h1 className='h1-ask'>Our Work</h1>
            </div>
            <div className="d-none d-md-block">
              <div className="btn-group">
                <div className="text-cente">
                  <a href="#" className="btn btn-ask btn-lg">View All Works</a>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Container className="text-center about-font">
          <Row className="shuffle-grid shuffle-grid-gap-14 shuffle-grid-cols-1 shuffle-grid-cols-md-2 shuffle-grid-cols-xl-4 mt-14" id="projects-grid">
            {projects.map((project, index) => (
              <Col key={project.id} xs={12} sm={6} md={3} className={`shuffle-grid-item mb-4`} data-groups={project.category}>
                <Card className="card card-square bg-transparent border-0">
                  <div className="card-bg" style={{ backgroundImage: `url(${project.image})` }}></div>
                  <div className="card-bg card-bg-hover"></div>
                  <Card.Body className="card-body card-body-hover d-flex align-items-center justify-content-center">
                    <i className="material-icons fs-8 zoom-icon">zoom_in</i>
                  </Card.Body>
                  <a href="#" className="card-link-overlay"></a>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Works;
