import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../assets/css/Overview.css'; // Import the CSS file for custom styling
import c1 from '../assets/images/c1.png';
import c2 from '../assets/images/c2.png';
import c3 from '../assets/images/c3.png';
import c4 from '../assets/images/c4.png';


const Overview = ({ handleShow }) => {
  const carouselItems = [
    { src: c1, caption: '', text: '' },
    { src: c2, caption: '', text: '' },
    { src: c3, caption: '', text: '' },
    { src: c4, caption: '', text: '' }
  ];

  return (
    <Carousel>
      {carouselItems.map((item, index) => (
        <Carousel.Item key={index} className="carousel-item-fullscreen" onClick={handleShow}>
          <div className="carousel-image-wrapper">
            <img
              className="d-block w-100 carousel-image"
              src={item.src}
              alt={`Slide ${index}`}
            />
          </div>
          <Carousel.Caption>
            <h3>{item.caption}</h3>
            <p>{item.text}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Overview;
