import React, { useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import config from '../config.json'; // Import the configuration file
import '../assets/css/WhatsAppButton.css'; // For styling the button

const WhatsAppButton = ({ message }) => {
  const [isMobile, setIsMobile] = useState(false);
  const phoneNumber = config.whatsappPhoneNumber;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check initial screen size
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <button className="whatsapp-button" onClick={handleClick}>
      <FaWhatsapp />
      {!isMobile && <span> Chat with us on WhatsApp</span>}
    </button>
  );
};

export default WhatsAppButton;
