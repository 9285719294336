import React from 'react';
import '../assets/css/ChooseUs.css'; // Import the CSS file for custom styling
import image24 from '../assets/images/image-24.jpg';
import { FaCheck } from 'react-icons/fa'; // Importing the Font Awesome check mark icon


const ChooseUs = ({ handleShow }) => {
  return (
    <section className="section" id="why-choose-us">
      <div className="section-content text-center container">
        <small className="fs-1-ask font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">
          Best of the Best
        </small>
        <h1>Why Choose Us</h1>
      </div>
      <div className="sds-block mt-xl-20">
        <div
          className="sds-block-background sds-block-background-right"
          style={{ backgroundImage: `url(${image24})` }}
        />
        <div className="sds-block-foreground sds-block-foreground-left text-center text-xl-left">
          <ul className="list-unstyled mb-12">
            <li className="mb-6 d-flex align-items-center justify-content-center justify-content-xl-start">
              <span className="icon-background"><FaCheck className="checkmark-icon" /></span>
              &nbsp;10 years of finishing projects on time and on budget
            </li>
            <li className="mb-6 d-flex align-items-center justify-content-center justify-content-xl-start">
              <span className="icon-background"><FaCheck className="checkmark-icon" /></span>
              &nbsp;Easy to understand contracts
            </li>
            <li className="mb-6 d-flex align-items-center justify-content-center justify-content-xl-start">
              <span className="icon-background"><FaCheck className="checkmark-icon" /></span>
              &nbsp;Knowledgeable project managers who help along the way
            </li>
            <li className="mb-6 d-flex align-items-center justify-content-center justify-content-xl-start">
              <span className="icon-background"><FaCheck className="checkmark-icon" /></span>
              &nbsp;Modern design trends using high-quality materials
            </li>
            <li className="d-flex align-items-center justify-content-center justify-content-xl-start">
              <span className="icon-background"><FaCheck className="checkmark-icon" /></span>
              &nbsp;Long list of satisfied New York State clients
            </li>
          </ul>
          <a href="#" className="btn btn-ask btn-lg" onClick={(e) => { e.preventDefault(); handleShow(); }}>Get a free estimate</a>
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;
