import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { ListGroup } from 'react-bootstrap';
import { Link as ScrollLink } from 'react-scroll';
import '../assets/css/Header.css';
import logo from '../assets/images/ask-logo-512-512px.png';


const Header = ({ handleShow }) => {
  const [isHeaderTopLineVisible, setIsHeaderTopLineVisible] = useState(true);

  const handleCloseHeaderTopLine = () => {
    setIsHeaderTopLineVisible(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      const scrollPos = window.scrollY + 150; // Adjust this value as needed to match the offset

      sections.forEach(section => {
        const sectionId = section.getAttribute('id');
        const navLink = document.querySelector(`.nav-link[href='#${sectionId}'], .nav-link[data-to='${sectionId}']`);

        if (navLink) {
          if (
            section.offsetTop <= scrollPos &&
            (section.offsetTop + section.offsetHeight) > scrollPos
          ) {
            navLink.classList.add('active');
          } else {
            navLink.classList.remove('active');
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="header header-fixed-xl">
      {isHeaderTopLineVisible && (
        <div className="header-top-line ask-bg-primary d-flex justify-content-between align-items-center">
          <Container>
            <Row className="w-100">
              <Nav className="navbar navbar-expand navbar-dark navbar-short justify-content-between w-100">
                <span className="navbar-text d-flex justify-content-end align-items-center w-100">
                  <span className="text-white mr-1">
                    Enjoy 0% GST On Home Interiors! Offer valid until 31st Oct 2024. Hurry, <a href='#' onClick={(e) => { e.preventDefault(); handleShow(); }}>Book Now!</a>
                  </span>
                  <button className="close-button ml-auto" onClick={handleCloseHeaderTopLine}>×</button>
                </span>
              </Nav>
            </Row>
          </Container>
        </div>
      )}
      <div className="header-main bg-white">
        <Container>
          <Row>
            <Navbar expand="lg" className="navbar-expand-lg navbar-light w-100" id="header-navbar">
              <Navbar.Brand className='font-weight-bold' href="/">
                <img
                  src={logo}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                  alt="Ask Interior Logo"
                />
                {' '}
                Ask Interior
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarSupportedContent" />
              <Navbar.Collapse id="navbarSupportedContent">
                <ListGroup as="ul" className="navbar-nav ml-auto borderless">
                  <ListGroup.Item as="li" className="nav-item">
                    <ScrollLink to="about" smooth={true} duration={500} offset={-100} activeClass="active" className="nav-link text-uppercase" data-to="about">About</ScrollLink>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" className="nav-item">
                    <ScrollLink to="services" smooth={true} duration={500} offset={-100} activeClass="active" className="nav-link text-uppercase" data-to="services">Services</ScrollLink>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" className="nav-item">
                    <ScrollLink to="why-choose-us" smooth={true} duration={500} offset={-100} activeClass="active" className="nav-link text-uppercase" data-to="why-choose-us">Why choose us</ScrollLink>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" className="nav-item">
                    <ScrollLink to="our-work" smooth={true} duration={500} offset={-100} activeClass="active" className="nav-link text-uppercase" data-to="our-work">Our work</ScrollLink>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" className="nav-item">
                    <ScrollLink to="testimonials" smooth={true} duration={500} offset={-100} activeClass="active" className="nav-link text-uppercase" data-to="testimonials">Testimonials</ScrollLink>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" className="nav-item">
                    <ScrollLink to="contact" smooth={true} duration={500} offset={-100} activeClass="active" className="nav-link text-uppercase" data-to="contact">Contacts</ScrollLink>
                  </ListGroup.Item>
                </ListGroup>
              </Navbar.Collapse>
            </Navbar>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Header;
