import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import '../assets/css/Contact.css'; // Import the CSS file for custom styling
import GoogleMapComponent from './GoogleMapComponent';
import config from '../config.json'; // Import the configuration file
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    service: '',
    zipCode: '',
    message: '',
    termsAccepted: false,
  });

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSubmit = {
      template: config.templateContactMail,
      clientId: config.clientId,
      toEmailList: config.toEmailListContactMail,
      bccList: config.bccListContactMail,
      ccList: config.ccListContactMail,
      subject: config.subjectContactMail,
      fromEmail: config.fromEmailContactMail,
      senderPersonilisedName: config.senderPersonilisedNameContactMail,
      attachment: null,
      attachFile: null,
      sendDate: new Date().toISOString(),
      status: true,
      mailData: formData,
    };

    try {
      const response = await axios.post(config.apiEndpointContactMail, dataToSubmit);
      console.log('Form submitted successfully:', response.data);
      // Optionally clear the form after successful submission
      setFormData({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        service: '',
        zipCode: '',
        message: '',
        termsAccepted: false,
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  const address = "Ask Interior, BF 53, Action Area I, Newtown, Kolkata, IN, 700156";
  const coordinates = { lat: 22.580458, lng: 88.468961 };

  return (
    <section id="contact">
      <div className="section">
        <div className="section-content container">
          <div className="text-center">
            <small className="fs-1-ask font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">
              We answer within 24 hours
            </small>
            <h1>Contact Us</h1>
          </div>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <Form className="cucol row mt-17" onSubmit={handleSubmit}>
                <Col xs={12} sm={6}>
                  <Form.Group className='form-group' controlId="firstName">
                    <Form.Label>First name</Form.Label>
                    <Form.Control type="text" value={formData.firstName} onChange={handleChange} />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className='form-group' controlId="lastName">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control type="text" value={formData.lastName} onChange={handleChange} />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className='form-group' controlId="phone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="text" value={formData.phone} onChange={handleChange} />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className='form-group' controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" value={formData.email} onChange={handleChange} />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className='form-group' controlId="service">
                    <Form.Label>Select the service</Form.Label>
                    <Form.Control as="select" value={formData.service} onChange={handleChange}>
                      <option value="">Select a service</option>
                      <option value="Interior Decoration">Interior Decoration</option>
                      <option value="Kitchen Remodeling">Kitchen Remodeling</option>
                      <option value="Bathroom Remodeling">Bathroom Remodeling</option>
                      <option value="Roofing">Roofing</option>
                      <option value="Windows & Doors">Windows & Doors</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className='form-group' controlId="zipCode">
                    <Form.Label>Your pin code</Form.Label>
                    <Form.Control type="text" value={formData.zipCode} onChange={handleChange} />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group className='form-group' controlId="message">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} value={formData.message} onChange={handleChange} />
                  </Form.Group>
                </Col>
                <Col xs={12} className="d-flex justify-content-between align-items-left flex-column flex-md-row">
                  <Form.Group controlId="termsAccepted" className="form-group mb-9 mb-md-0">
                    <Form.Check type="checkbox" label="I've read and agree with the Terms & Policy" checked={formData.termsAccepted} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group className="form-group mb-0">
                    <Button className="btn btn-ask btn-lg" type="submit">Send message</Button>
                  </Form.Group>
                </Col>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      <Container className="py-19 py-lg-20">
        <Row className="vertical-divider-light">
          <Col xs={12} md={6} lg={3}>
            <Card className="border-0">
              <Card.Body className="py-17 px-10 text-center">
                <div className="card-icon mb-6"><i className="material-icons">location_on</i></div>
                <div className="fs-1-ask lh-1 my-5 font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">
                  By appointment only
                </div>
                <p className="mb-0 text-body">BF 53, Street 169<br />Block BF, Action Area I<br />New Town, West Bengal - 700156</p>
              </Card.Body>              
            </Card>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Card className="border-0">
              <Card.Body className="py-17 px-10 text-center">
                <div className="card-icon mb-6"><i className="material-icons">phone</i></div>
                <div className="fs-1-ask lh-1 my-5 font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">
                  Mobile phone
                </div>
                <p className="mb-0 text-body">+91 79082 71270</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Card className="border-0">
              <Card.Body className="py-17 px-10 text-center">
                <div className="card-icon mb-6"><i className="material-icons">access_time</i></div>
                <div className="fs-1-ask lh-1 my-5 font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">
                  Working hours
                </div>
                <p className="mb-0 text-body">10:00 AM - 22:00 PM</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Card className="border-0">
              <Card.Body className="py-17 px-10 text-center">
                <div className="card-icon mb-6"><i className="material-icons">alternate_email</i></div>
                <div className="fs-1-ask lh-1 my-5 font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">
                  Email
                </div>
                <p className="mb-0 text-body">santu@askinterior.co.in</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <div className="section-content container mt-5">
        <GoogleMapComponent address={address} coordinates={coordinates} height="600px" />
      </div>
    </section>
  );
};

export default Contact;
