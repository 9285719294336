import React, { useState, useRef } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { BsFillHouseDoorFill, BsFillBrushFill, BsTools, BsPeopleFill, BsRecycle } from 'react-icons/bs';
import '../assets/css/About.css'; // Import the CSS file for custom styling

const About = () => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const containerRef = useRef(null);
	const cardWidth = 280; // Adjust this value according to the width of your cards
	const visibleCards = 4;

	const cards = [
		{
			icon: <BsFillHouseDoorFill size={45} className="mb-3" />,
			title: 'Creativity',
			text: 'Transforming spaces with unique designs that blend functionality and artistry, tailored to reflect your individuality and vision.'
		},
		{
			icon: <BsFillBrushFill size={45} className="mb-3" />,
			title: 'Attention to Detail',
			text: 'Every element meticulously crafted with high precision and fittings bring out the real value'
		},
		{
			icon: <BsTools size={45} className="mb-3" />,
			title: 'Strong Portfolio',
			text: 'Showcasing a wide range of styles and spaces, highlighting our versatility and expertise through successful transformations.'
		},
		{
			icon: <BsPeopleFill size={45} className="mb-3" />,
			title: 'Professionalism',
			text: 'Conducting business with the highest ethical standards, building trust and long-term relationships with our clients.'
		},
		{
			icon: <BsRecycle size={45} className="mb-3" />,
			title: 'Sustainable Practices',
			text: 'Integrating eco-friendly materials and energy-efficient solutions to create beautiful, sustainable spaces.'
		}
	];

	const handlePrevClick = () => {
		const newIndex = currentIndex === 0 ? cards.length - visibleCards : currentIndex - 1;
		setCurrentIndex(newIndex);
		scrollToCard(newIndex);
	};

	const handleNextClick = () => {
		const newIndex = (currentIndex + 1) % cards.length;
		setCurrentIndex(newIndex);
		scrollToCard(newIndex);
	};

	const scrollToCard = (index) => {
		const scrollOffset = index * cardWidth;
		containerRef.current.scrollTo({
			left: scrollOffset,
			behavior: 'smooth'
		});
	};

	return (
		<div className="about-section"> {/* Wrap the About component with a div */}
			<section id="about" className="section">
				<Container className="text-center about-font">
					<small className="fs-1-ask font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">
						The Interior Decorator You Need
					</small>
					<h1 className="mb-0 h1-ask">Ask Interior</h1>
					<Row className="mt-6 mt-md-10 mt-lg-17">
						<Col xs={12} lg={{ span: 10, offset: 1 }}>
							<p className="lead">
								Welcome to Ask Interior, your premier interior design and contracting company in India. Our team of experienced professionals is dedicated to transforming your spaces into stunning and functional environments. We pride ourselves on our attention to detail, quality craftsmanship, and exceptional customer service.
							</p>
						</Col>
					</Row>
					<Row className="mt-6 mt-md-10 mt-lg-17 ">
						<Col xs={12} lg={{ span: 10, offset: 1 }}>
							<h2 className="mb-4">With Us</h2>
							<div className='about-row'>
								<div className="about-container" ref={containerRef}>
									{cards.map((card, index) => (
										<Card key={index} className={`about-card mx-2 ${index === currentIndex ? 'active' : ''}`}>
											<Card.Body className="text-center">
												{card.icon}
												<Card.Title>{card.title}</Card.Title>
												<Card.Text className="card-text text-sm">{card.text}</Card.Text>
											</Card.Body>
										</Card>
									))}
								</div>
								<div className="nav-buttons">
									<Button className="prev-button" onClick={handlePrevClick}>
										<span className="bi bi-arrow-left carousel-control-prev-icon"></span>
									</Button>
									<Button className="next-button" onClick={handleNextClick}>
										<span className="bi bi-arrow-right carousel-control-next-icon"></span>
									</Button>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</div>
	);
};

export default About;
