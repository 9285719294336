import React, { useRef } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import '../assets/css/Testimonials.css'; // Import the CSS file for custom styling

const testimonialsData = [
	{
		customerName: 'Deepok Ganguly',
		service: 'Entire Flat Decoration',
		text: 'The team did an amazing job with our flat. It looks stunning and we couldn’t be happier!',
	},
	{
		customerName: 'Amit Biswas',
		service: 'Kitchen Remodeling',
		text: 'Our new kitchen is fantastic. The design and functionality are perfect. Highly recommend!',
	},
	{
		customerName: 'Sayani Mondal',
		service: 'Bathroom Renovation',
		text: 'The bathroom renovation exceeded our expectations. The quality and attention to detail are superb.',
	},
	{
		customerName: 'Rik Bhattacharya',
		service: 'Living Room Makeover',
		text: 'The living room makeover has transformed our space into a cozy and stylish area. Excellent work!',
	},
	{
		customerName: 'Rohini Saha',
		service: 'Bedroom Redesign',
		text: 'The bedroom redesign has made our room much more comfortable and aesthetically pleasing. Fantastic job!',
	},
	{
		customerName: 'Kaushik Roy',
		service: 'Home Office Setup',
		text: 'Our home office setup is now very functional and stylish. The team understood our needs perfectly.',
	},
	{
		customerName: 'Priya Dutta',
		service: 'Garden Landscaping',
		text: 'The garden landscaping has added a beautiful outdoor space to our home. Very pleased with the result!',
	},
	{
		customerName: 'Arjun Sen',
		service: 'Dining Room Design',
		text: 'The dining room design has made our family dinners much more enjoyable. The space is both elegant and practical.',
	}
	// Add more testimonials as needed
];

const Testimonials = () => {
	const scrollContainerRef = useRef(null);

	const scrollLeft = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
		}
	};

	const scrollRight = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
		}
	};

	return (
		<section id="testimonials" className="section">
			<Container className="testimonials-section my-5">
				<h2 className="text-center mb-4">Testimonials</h2>
				<Row className="flex-row flex-nowrap overflow-auto testimonials-row" ref={scrollContainerRef}>
					{testimonialsData.map((testimonial, index) => (
						<Col key={index} className="d-flex">
							<Card className="testimonial-card mx-2">
								<Card.Body>
									<Card.Title>{testimonial.customerName}</Card.Title>
									<Card.Subtitle className="mb-2 text-muted">{testimonial.service}</Card.Subtitle>
									<Card.Text>{testimonial.text}</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
				<div className="scroll-buttons d-flex justify-content-between mb-3">
					<Button variant="secondary" onClick={scrollLeft}>
						&lt;
					</Button>
					<Button variant="secondary" onClick={scrollRight}>
						&gt;
					</Button>
				</div>

			</Container>
		</section>
	);
};

export default Testimonials;
