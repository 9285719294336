import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Landing from './pages/Landing';
import ModalHeader from './components/ModalHeader';

function App() {
	
	const [modalShow, setModalShow] = useState(false);

	const handleShow = () => setModalShow(true);
	const handleClose = () => setModalShow(false);
	
	return (
		<div className="App">
			<Header handleShow={handleShow}/>
			<Landing />
			<Footer />
			<ModalHeader show={modalShow} handleClose={handleClose} />
		</div>
	);
}

export default App;
