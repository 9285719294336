import React from 'react';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';

const GoogleMapComponent = ({ address, coordinates, height = '500px' }) => {
  const containerStyle = {
    width: '100%',
    height: height,
  };

  const center = {
    lat: coordinates.lat,
    lng: coordinates.lng,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyDQQ-zSddPUwZFI_WBNiApt-1g8sYNTcr0">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={16}>
        <MarkerF position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapComponent;
