import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Footer.css'; // Import the CSS file for custom styling

const Footer = () => {

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	return (
		<section className="footer">
			<Container className="py-5 mt-5">
				<Row className="align-items-center">
					{/* Brand & Social Icons */}
					<Col xs={12} md={6} lg={5} className="mb-4 mb-md-0 text-center text-md-left">
						<div className="d-flex align-items-center justify-content-center justify-content-md-start">
							<a href="index.html" className="footer-brand h2 text-dark mr-5"> {/* Added more margin to the right */}
								Ask Interior &nbsp;
							</a>
							<div className="d-flex">
								<a href="https://www.facebook.com" className="text-dark mr-4" target="_blank" rel="noopener noreferrer">
									<i className="material-icons">facebook</i>
								</a>

								<a href="https://www.linkedin.com" className="text-dark mr-4" target="_blank" rel="noopener noreferrer">
									<i className="material-icons">linkedin</i>
								</a>

							</div>
						</div>
					</Col>

					{/* Footer Menu */}
					<Col xs={12} md={6} lg={5} className="text-center text-md-right mb-4 mb-md-0">
						<ul className="list-inline mb-0">
							<li className="list-inline-item">
								<a href="#" className="text-dark">Privacy Policy</a>
							</li>
							<li className="list-inline-item">
								<a href="#" className="text-dark">Terms & Conditions</a>
							</li>
						</ul>
					</Col>

					{/* Up Arrow */}
					<Col xs={12} lg={2} className="text-center text-lg-right mb-4 mb-lg-0">
						<a href="#page-start-anchor" className="footer-icon text-dark"  onClick={scrollToTop} data-toggle="smooth-scroll">
							<i className="material-icons">arrow_upward</i>
						</a>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Footer;
