import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import laltern from '../assets/images/consult.jpg';
import '../assets/css/ModalHeader.css';
import config from '../config.json'; // Import the configuration file

const ModalHeader = ({ show, handleClose }) => {
	const [selectedPropertyType, setSelectedPropertyType] = useState(null);
	const [formData, setFormData] = useState({
		propertyLocation: '',
		name: '',
		mobileNumber: '',
		whatsappUpdates: false,
	});

	const handlePropertyTypeClick = (type) => {
		setSelectedPropertyType(type);
	};

	const handleChange = (e) => {
		const { id, value, type, checked } = e.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[id]: type === 'checkbox' ? checked : value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const dataToSubmit = {
			template: config.templateInquiryMail,
			clientId: config.clientId,
			toEmailList: config.toEmailListInquiryMail,
			bccList: config.bccListInquiryMail,
			ccList: config.ccListInquiryMail,
			subject: config.subjectInquiryMail,
			fromEmail: config.fromEmailInquiryMail,
			senderPersonilisedName: config.senderPersonilisedNameInquiryMail,
			attachment: null,
			attachFile: null,
			sendDate: new Date().toISOString(),
			status: true,
			mailData: {
				...formData,
				propertyType: selectedPropertyType,
			},
		};

		try {
			const response = await axios.post(config.apiEndpointInquiryMail, dataToSubmit);
			console.log('Form submitted successfully:', response.data);
			handleClose(); // Optionally close the modal after successful submission
		} catch (error) {
			console.error('Error submitting form:', error);
		}
	};

	return (
		<Modal show={show} onHide={handleClose} size="lg" centered className="inquiry-modal">
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body className="header-modal">
				<Row>
					<Col md={6} className="d-none d-md-block inquiry-image-section">
						<img src={laltern} alt="Design Consultation" className="img-fluid" />
						<p className="offer-text">Enjoy 0% GST on Home Interiors. Offer valid until 31st May 2024.</p>
					</Col>
					<Col lg={6}>
						<h4>Get a <b>free design consultation</b></h4>
						<Form onSubmit={handleSubmit}>
							<Form.Group className='mb-3' controlId="propertyType">
								<Form.Label>Property type</Form.Label>
								<div className="property-type-section">
									{['1 BHK', '2 BHK', '3 BHK', '4+ BHK / Duplex'].map((type) => (
										<div
											key={type}
											className={`property-type-option ${selectedPropertyType === type ? 'selected' : ''}`}
											onClick={() => handlePropertyTypeClick(type)}
										>
											{type}
										</div>
									))}
								</div>
							</Form.Group>
							<Form.Group className='mb-3' controlId="propertyLocation">
								<Form.Label>Property Location</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter location"
									value={formData.propertyLocation}
									onChange={handleChange}
								/>
							</Form.Group>
							<Form.Group className='mb-3' controlId="name">
								<Form.Label>Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter name"
									value={formData.name}
									onChange={handleChange}
								/>
							</Form.Group>
							<Form.Group className='mb-3' controlId="mobileNumber">
								<Form.Label>Mobile Number</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter mobile number"
									value={formData.mobileNumber}
									onChange={handleChange}
								/>
							</Form.Group>
							<Form.Group className='mb-3' controlId="whatsappUpdates">
								<Form.Check
									type="checkbox"
									label="Yes, send me updates via WhatsApp"
									checked={formData.whatsappUpdates}
									onChange={handleChange}
								/>
							</Form.Group>
							<Button variant="primary" className='btn-ask-md' type="submit" block>
								Book a Free Consultation
							</Button>
						</Form>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	);
};

export default ModalHeader;
