import React from 'react';
import { Container, Row, Col, Card, Button, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import '../assets/css/Services.css'; // Adjust the path to your CSS file
import CustomCard from './CustomCard';
import ss1 from '../assets/images/ss1.jpg';
import s1 from '../assets/images/s-modern-lroom.jpg';
import s2 from '../assets/images/s-modern-entryway.jpg';
import s3 from '../assets/images/s-classic-bedroom.jpg';
import s4 from '../assets/images/s-roommate.jpg';
import s5 from '../assets/images/s-ecosofa.jpg';
import s6 from '../assets/images/s-classic-bedroom2.jpg';






const Services = ({ handleShow }) => {

	const cardsData = [
		{
			image: s6,
			header: 'Modern Minimalist Design',
			description: 'Elevate your space with clean lines, sleek furniture, and a focus on functionality. Our modern minimalist designs create an atmosphere of simplicity and sophistication.'
		},
		{
			image: s5,
			header: 'Luxurious Contemporary Interiors',
			description: 'Indulge in opulence with our luxurious contemporary interiors. From plush fabrics to exquisite finishes, we bring elegance and refinement to every corner of your home.'
		},
		{
			image: s3,
			header: 'Timeless Classic Design',
			description: 'Experience the enduring charm of timeless classic design. Our expert team seamlessly blends traditional elements with modern sensibilities, creating spaces that stand the test of time.'
		},
		{
			image: s4,
			header: 'Cozy Scandinavian Style',
			description: 'Embrace warmth and simplicity with our cozy Scandinavian style. Featuring natural materials, neutral colors, and plenty of natural light, our designs bring a sense of serenity to any room.'
		},
		{
			image: s1,
			header: 'Eco-Friendly Green Interiors',
			description: 'Make a sustainable statement with our eco-friendly green interiors. From recycled materials to energy-efficient solutions, we prioritize environmental responsibility without compromising on style.'
		},
		{
			image: s2,
			header: 'Innovative Smart Home Solutions',
			description: 'Step into the future with our innovative smart home solutions. Seamlessly integrate technology into your space for added convenience, comfort, and security, all while maintaining impeccable style.'
		}
		// Add more card data as needed
	];

	return (
		<div className="about-section"> {/* Wrap the About component with a div */}
			<section id="services" className="section">
				<Container className="text-center about-font">
					<div className="d-flex justify-content-center justify-content-md-between align-items-end">
						<div className="text-center text-md-left"><small
							className="fs-1-ask font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">What
							we do the best</small>
							<h1 className='h1-ask'>Services</h1>
						</div>
						<div className="d-none d-md-block">
							<div className="btn-group">
								<div className="text-cente">
									<a href="#" className="btn btn-ask btn-lg ">View All</a>
								</div>
							</div>
						</div>
					</div>
				</Container>
				<Container className="custom-card-container">
					<Row className='services-card-row'>
						{cardsData.map((card, index) => (
							<Col xs={12} md={6} key={index}>
								<CustomCard
									image={card.image}
									header={card.header}
									description={card.description}
								/>
							</Col>
						))}
					</Row>
					<Row className='row'>
						<div className="d-none d-md-block mt-4">
							<div className="btn-group">
								<div className="text-cente">
									<a href="#" className="btn btn-ask btn-lg" onClick={(e) => { e.preventDefault(); handleShow(); }}>Get Free Estimate</a>
								</div>
							</div>
						</div>
					</Row>
				</Container>
			</section>
		</div>
	);
};

export default Services;
