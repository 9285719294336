import React, { useState } from 'react';
import Overview from '../components/Overview';
import About from '../components/About';
import Services from '../components/Services';
import ChooseUs from '../components/ChooseUs';
import Works from '../components/Works';
import Testimonials from '../components/Testimonials';
import Contact from '../components/Contact';
import InquiryModal from '../components/InquiryModal';
import WhatsAppButton from '../components/WhatsAppButton';

const Landing = () => {

	const [modalShow, setModalShow] = useState(false);
	const phoneNumber = '8158098897'; // Replace with the actual phone number
	const message = 'Hello, I would like to inquire about...'; // Default message


	const handleShow = () => setModalShow(true);
	const handleClose = () => setModalShow(false);

	return (
		<div>
			<Overview handleShow={handleShow} />
			<About handleShow={handleShow} />
			<Services handleShow={handleShow} />
			<ChooseUs handleShow={handleShow} />
			<Works handleShow={handleShow} />
			<Testimonials handleShow={handleShow} />
			<Contact handleShow={handleShow} />
			<InquiryModal show={modalShow} handleClose={handleClose} />
			<WhatsAppButton phoneNumber={phoneNumber} message={message} />
		</div>
	);
};

export default Landing;
